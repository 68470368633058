import { Col } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { allapiAction } from '../../Redux/common/action';
import HeadingBack from '../component/HeadingBack'
import moment from "moment";
import ReactPaginate from 'react-paginate'
function MonthReports2() {


    const [datesele, setdatesele] = useState(new Date())
    const [pageCount, setPageCount] = useState(0)
    const [pageNo, setpageNo] = useState(1)

    const dispatch = useDispatch()
    const month_report_2 = useSelector((state) =>
    state?.allapi?.month_report_2 ? state?.allapi?.month_report_2 : {}
  );
  const month_report = month_report_2?.docs ? month_report_2?.docs : []
  useEffect(() => {
    setPageCount(month_report_2?.totalPages)
  }, [month_report_2])

  const hendleTosubmit = (e) => {
    console.log(e?.target?.value)

    // const dateStr = "2023-08-13";
    const date = new Date(e?.target?.value)
    setdatesele(date)
    const year = date.getFullYear() // 2023
    const month = date.getMonth() + 1
    console.log(year)
    console.log(month)
    setpageNo(1)
    dispatch(allapiAction.teamCommissionByDateBypagi({year, month, pageNumber: 1}))
  }
  useEffect(() => {
    const date = new Date()

    const year = date.getFullYear() // 2023
    const month = date.getMonth() + 1
    console.log(year)
    console.log(month)

    dispatch(allapiAction.teamCommissionByDateBypagi({year, month, pageNumber: 1}))
    // teamCommissionByDate
  }, [])

  const handlePageClick = (event) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1

    setpageNo(data)

    // if (!userSearch) {
    const date = datesele

    const year = date.getFullYear() // 2023
    const month = date.getMonth() + 1
    console.log(year)
    console.log(month)

    dispatch(allapiAction.teamCommissionByDateBypagi({year, month, pageNumber: data}))

    return () => {}
    // } else {
    //   dispatch(
    //     ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
    //   );

    //   return () => {};
    // }
  }
  return (
    <div>
         <div className="flex">
        <div>
          <HeadingBack
            title={"Month Reports"}
            // subHeading={`Total ${get_product_list?.totalDocs || 0} Product`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
        <input
          type='month'
          className='form-control'
          onChange={(e) => {
            hendleTosubmit(e)
          }}
        />
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/add-product")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>
      <div>
        <table className='table monthReport'>
          <thead>
            <tr>
              <th scope='col'>#</th>
              <th scope='col'>Full Name(SB)---Rank</th>
              <th scope='col'>Sponsor Name(SB)---Rank</th>
              <th scope='col'>Total Sale</th>
              <th scope='col'>Commision</th>
              <th scope='col'>Date</th>
              <th scope='col'>description</th>
            </tr>
          </thead>
          <tbody>
            {month_report &&
              month_report?.map((data, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{data?.fullName}</td>
                    <td>{data?.sponsorName}</td>
                    <td>{data?.total_sale}</td>
                    <td>{data?.commission}</td>
                    <td>{moment(data?.sale_date).format('DD MMM  YYYY hh:mm a ')}</td>
                    <td>{data?.description}</td>
                  </tr>
                )
              })}

            {/* <tr>
           
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr> */}
          </tbody>
        </table>
        <ReactPaginate
          breakLabel='...'
          nextLabel='next'
          className='dataTables_paginate paging_simple_numbers category'
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel='previous'
          // renderOnZeroPageCount={null}
          forcePage={pageNo-1}
        />
      </div>
    
    </div>
  )
}

export default MonthReports2