import * as moment from "moment";

export function SetToken(token) {
  window.localStorage.setItem("admin_token", token || "");
}

export function GetToken() {
  let token = window.localStorage.getItem("admin_token") || "";
  // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NDksImZpcnN0TmFtZSI6Im1vaGFtbWVkIGFyc2hhZCIsImlhdCI6MTY4MjkyMjEwNX0.ZZvIgF4rpqba3SAdcOdG5-Nd1b_2_aKViKj1VEt_UaM"
  return token;
}
export function DeleteToken() {
  window.localStorage.removeItem("admin_token");
}
export function GetLoggedInUser() {
  let token = GetToken();
  if (token) {
    // const user = jwt_decode(token);
    return token;
  } else {
    return {};
  }
}
export function isSuperAdmin() {
  let LODDED_USER = GetLoggedInUser();
  if (LODDED_USER?.role_id == 1) {
    return true;
  } else {
    return false;
  }
}

export function DateFormat(date) {
  return (date && moment(date).format("MMM DD YYYY")) || "";
}
export function getDatesInRange(first, second) {
  const date = new Date(first?.getTime());
  const dates = [];
  while (date <= second) {
    dates.push(moment(date).format("YYYY-MM-DD"));
    date.setDate(date.getDate() + 1);
  }
  return dates;
}
export function DayFrom(date) {
  if (date) {
    const firstDate = new Date(date);
    var diffDays = moment().diff(firstDate, "days");
    return diffDays;
  } else {
    return "";
  }
}
export function FromNow(date) {
  return (date && moment(date).fromNow()) || "";
}
export function onlyMobileInput() {
  let mobile = document.getElementById("only_mobile");
  let mobile1 = document.getElementById("only_mobile1");
  let mobile2 = document.getElementById("only_mobile2");
  let mobile3 = document.getElementById("only_mobile3");
  if (mobile) {
    mobile.addEventListener("input", function () {
      if (/\D/g.test(this.value)) {
        this.value = this.value.replace(/\D/g, "");
      }
    });
  }
  if (mobile1) {
    mobile1.addEventListener("input", function () {
      if (/\D/g.test(this.value)) {
        this.value = this.value.replace(/\D/g, "");
      }
    });
  }
  if (mobile2) {
    mobile2.addEventListener("input", function () {
      if (/\D/g.test(this.value)) {
        this.value = this.value.replace(/\D/g, "");
      }
    });
  }
  if (mobile3) {
    mobile3.addEventListener("input", function () {
      if (/\D/g.test(this.value)) {
        this.value = this.value.replace(/\D/g, "");
      }
    });
  }
}

export function isNegative(num) {
  if (Math.sign(num) === -1) {
    return true;
  }
  return false;
}
export function getCurrentYear() {
  let currentYear = moment(new Date()).format("YYYY");
  return currentYear;
}
export function getDaysByMonth() {
  var daysArr = [];
  var monthDate = moment().startOf("month");
  for (let i = 0; i < monthDate.daysInMonth(); i++) {
    let newDay = monthDate.clone().add(i, "days");
    daysArr.push({
      date: newDay.format("D"),
      month: newDay.format("MMM"),
      value: newDay.format("DD"),
    });
  }
  return daysArr;
}
export function ShowMenu(Module) {
  let LOGGED_IN_USER = GetLoggedInUser();
  if (LOGGED_IN_USER?.role_id === 1) {
    return "";
  } else {
    let permission = JSON.parse(LOGGED_IN_USER?.permission || "[]");
    let obj = permission.find(
      (ele) => Array.isArray(Module) && Module.includes(ele.module)
    );
    // console.log(obj, Module);
    if (obj?.permission && obj?.permission >= 1) {
      return "";
    } else {
      return "";
    }
  }
}
