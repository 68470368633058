import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { Table } from "antd";
import { URL } from "../../Redux/common/url";

export default function SetLocation() {
  const [file, setFile] = useState(null);
  const [stateCityData, setStateCityData] = useState(null);
  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append("file", file);

    dispatch(allapiAction.uploadStateCountryData(formData))
    .then((result) => {
      setStateCityData(result);
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    fetchStateCityData();
  }, []);

  function fetchStateCityData() {
    fetch(`${URL.API_BASE_URL}/publicApi/get/locationData`, {})
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStateCityData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const columns = [
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State Code",
      dataIndex: "state_code",
      key: "state_code",
    },
  ];

  return (
    <div className="container">
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>

      <div className="mt-15">
        <Table columns={columns} dataSource={stateCityData} rowKey="_id" />
      </div>
    </div>
  );
}
