import React, { useEffect } from "react";
import { Col, Row } from "antd";
import Boxes from "./Boxes";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import axios from "axios";

import { Chart } from "react-google-charts";
import Boxes2 from "./Boxes2";
import UserrTable from "./UserrTable";
import CenteralHealthLine from "./CenteralHealthLineChart/CenteralHealthLine";
import UpiPaymentSDK from "./UpiPaymentSDK";
function Home() {
  const dispatch = useDispatch();
  const get_category_sale = useSelector((state) =>
    state?.allapi?.get_category_sale ? state?.allapi?.get_category_sale : []
  );
  useEffect(() => {
    dispatch(allapiAction.dashboard({}));
    dispatch(allapiAction.categoryWiseSale({}));

    return () => {};
  }, []);

  const data2 = [
    ["Year", "Sales", "Expenses"],
    ["Monday", 20000, 30000],
    ["Tuesday", 35000, 15000],
    ["Wednesday", 38000, 14000],
    ["Thursday", 25000, 35000],
    ["Friday", 34000, 22000],
    ["Saturday", 42000, 34000],
    ["Sunday", 18000, 38000],
  ];
  const data = get_category_sale;

  const options = {
    title: "Company Performance",
    curveType: "function",
    legend: { position: "bottom" },
  };
  const handlePayment = async () => {
    const paymentConfig = {
      vpa: "9610641351@ybl", // Replace with the receiver's UPI ID
      amount: "1.00", // Amount for testing
      payeeName: "ReceiverName",
      transactionRef: "TXN12345VS", // Unique transaction reference
      transactionNote: "Payment fortesting",
    };
    // upi://pay?pa=9610641351%40ybl&pn=ReceiverName&tid=TXN12345VS&am=1.00&tn=Payment%20fortesting&cu=INR&url=
    try {
      const response = await UpiPaymentSDK.initializePayment(paymentConfig);
      console.log("Payment Response:", response);
      alert("Payment Process Initiated!");
    } catch (error) {
      console.error("Payment Error:", error);
      alert(`Payment Failed: ${error.message}`);
    }
  };
  return (
    <div>
      <Row gutter={[20, 20]} type="flex">
        <Col lg={18} md={24} sm={24} xs={24}>
        <div>
          <h1>UPI Payment Example</h1>
          <button onClick={handlePayment}>Pay with UPI</button>
        </div>
          <div className=" p-10">
            {/* <CenteralHealthLine /> */}
            {/* <Boxes /> */}
            <Boxes2 />
            <div className="dashboardBoxShad m-5 mt-10  mb-10">
              <div className="flex justify-content-between">
                <div>
                  <div className="fw-600">Total Sale</div>
                </div>
                <div className="flex justify-content-end gap20 align-items-center">
                  <div className="flex gap30">
                    <div className="relative">
                      <span className="ct_chart_status beforeActive">
                        Category
                      </span>
                    </div>
                    {/* <div className="relative">
                      <span className="ct_chart_status beforeInactive">
                        Inactive
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
              <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                data={data}
                options={{
                  fontName: "Open Sans",
                  fontSize: 14,
                  color: "#212529",
                  tooltip: { isHtml: true },
                  legend: { position: "none" },
                  colors: ["#9365b8", "#1559a2"],
                  curveType: "function",
                  hAxis: {
                    textStyle: {
                      whiteSpace: "wrap",
                      fontName: "Open Sans",
                      fontSize: 14,
                      color: "#212529",
                    },
                  },
                  chartArea: {
                    left: 70,
                    top: 10,
                    bottom: 40,
                    width: "100%",
                    height: "100%",
                  },
                }}
              />
              
            </div>

            {/* <div className="dashboardBoxShad  p-10 m-5 mt-10">
              <Row>
                <Col span={24}>
                  <h2 className="p-10">Users</h2>
                </Col>
              </Row>

              <UserrTable />
            </div> */}
          </div>
        </Col>
        <Col lg={6} md={24} sm={24} xs={24}>
          <div>
            <Boxes />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Home;
