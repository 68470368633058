import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import { Modal } from "antd";
import { toast } from "react-toastify";
import { URL } from "../../Redux/common/url";

function LevelDynemic() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [pagev, setPage] = useState(1);
  const [sliderId, setSliderId] = useState("");
  const searchData = searchParams.get("search");
  const statusData2 = searchParams.get("status");

  const page = pagev;
  const search = searchData == null ? "" : searchData;
  const statusfinal = statusData2 == null ? "" : statusData2;

  const users_list = useSelector((state) =>
    state?.allapi?.get_marques ? state?.allapi?.get_marques : {}
  );
  const get_seller_profile = useSelector((state) =>
    state?.allapi?.get_seller_profile ? state?.allapi?.get_seller_profile : {}
  );

  const [userDetailsEdit, setUserDetailsEdit] = useState({});
  const [userKycEdit, setUserKycEdit] = useState({});
  const [images, setImages] = useState([]);
  const [aadharFront, setaadharFront] = useState([]);
  const [aadharBack, setaadharBack] = useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };
  const onChangeaadharFront = (imageList, addUpdateIndex) => {
    // data for submit
    setaadharFront(imageList);
  };
  const onChangeaadharBack = (imageList, addUpdateIndex) => {
    // data for submit
    setaadharBack(imageList);
  };

  const hendleToprofileEdit = (e) => {
    const { name, value } = e?.target;

    setUserDetailsEdit({ ...userDetailsEdit, [name]: value });
  };
  const hendleToprofileEditKyc = (e) => {
    const { name, value } = e?.target;

    setuserData({ ...userData, [name]: value });
  };
  useEffect(() => {
    setUserDetailsEdit({
      _id: get_seller_profile?._id,
      fullName: get_seller_profile?.fullName
        ? get_seller_profile?.fullName
        : "",
      guardian: get_seller_profile?.guardian
        ? get_seller_profile?.guardian
        : "",
      father_name: get_seller_profile?.father_name
        ? get_seller_profile?.father_name
        : "",
      email: get_seller_profile?.email ? get_seller_profile?.email : "",
      phone: get_seller_profile?.phone ? get_seller_profile?.phone : "",
      gender: get_seller_profile?.gender ? get_seller_profile?.gender : "",
      city: get_seller_profile?.city ? get_seller_profile?.city : "",
      state: get_seller_profile?.state ? get_seller_profile?.state : "",
      country: get_seller_profile?.country ? get_seller_profile?.country : "",
      zip: get_seller_profile?.zip ? get_seller_profile?.zip : "",
      marital: get_seller_profile?.marital ? get_seller_profile?.marital : "",
      professional: get_seller_profile?.professional
        ? get_seller_profile?.professional
        : "",
      address1: get_seller_profile?.address1
        ? get_seller_profile?.address1
        : "",
      address2: get_seller_profile?.address2
        ? get_seller_profile?.address2
        : "",
    });

    setUserKycEdit({
      pan_card: get_seller_profile?.kyc_id?.pan_card
        ? get_seller_profile?.kyc_id?.pan_card
        : "",
      aadhar_card: get_seller_profile?.kyc_id?.aadhar_card
        ? get_seller_profile?.kyc_id?.aadhar_card
        : "",
      ifsc: get_seller_profile?.kyc_id?.ifsc
        ? get_seller_profile?.kyc_id?.ifsc
        : "",
      acc_no: get_seller_profile?.kyc_id?.acc_no
        ? get_seller_profile?.kyc_id?.acc_no
        : "",
      _id: get_seller_profile?.kyc_id?._id,
    });

    if (get_seller_profile?.kyc_id?.pancardImage) {
      setImages([
        {
          data_url: URL.API_BASE_URL + get_seller_profile?.kyc_id?.pancardImage,
        },
      ]);
    }
    if (get_seller_profile?.kyc_id?.aadharfrontImage) {
      setaadharFront([
        {
          data_url:
            URL.API_BASE_URL + get_seller_profile?.kyc_id?.aadharfrontImage,
        },
      ]);
    }
    if (get_seller_profile?.kyc_id?.aadharbacktImage) {
      setaadharBack([
        {
          data_url:
            URL.API_BASE_URL + get_seller_profile?.kyc_id?.aadharbacktImage,
        },
      ]);
    }
  }, [get_seller_profile]);

  const listdata = users_list?.docs ? users_list?.docs : [];

  useEffect(() => {
    dispatch(allapiAction.getlevelList(page));

    return () => {};
  }, [location]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});

  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusUser, setstatusUser] = useState(false);
  const [statusLicence, setstatusLicence] = useState(false);
  const [statusEdit, setstatusEdit] = useState(false);
  const [statusEditKyc, setstatusEditKyc] = useState(false);
  const [statusKycAp, setstatusEditAp] = useState(false);

  const [kycStatus, setuserkycStatus] = useState({});

  const hendleTochangekyc = (e) => {
    const { name, value } = e.target;

    setuserkycStatus({ ...kycStatus, [name]: value });
  };

  const hendleToHideKyc = () => {
    setstatusEditKyc(false);
  };
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
  };
  const hendleToViewUser = (e) => {
    setstatusUser(true);
    setuserData(e);
    dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToViewLicence = (e) => {
    setstatusLicence(true);
    setuserData(e);
    dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToKycStatus = (e) => {
    setstatusEditAp(true);
    // setuserkycStatus(e);
    // dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToViewEdit = (e) => {
    setstatusEdit(true);
    setuserData(e);
    dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToViewEditKyc = (e) => {
    setstatusEditKyc(true);
    setuserData(e);
    // dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToKycSubmetUpdate = () => {
    if (!statusData?.commission) {
      toast.error("please enter commission");
    } /* else if (!statusData?.link) {
      toast.error("please enter link");
    } */ else {
      const data = new FormData();
      //   data.append("image", images[0]?.file);
      //   data.append("title", statusData?.title);
      //   data.append("link", statusData?.link);
      //   data.append("page", page);
      //   data.append("type", statusData?.type);
      //   data.append("onpage", statusData?.onpage);
      // images
      dispatch(allapiAction.createLeavel(statusData));

      setstatusEditAp(false);
    }
  };
  const hendleToSubmetUpdate = () => {
    dispatch(
      allapiAction.addFoundByAdmin({
        user_id: userData?._id,
        amount: statusData?.amount,

        pageNumber: page,
      })
    );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    setSliderId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.deleteLevel({
        page: page,
        _id: sliderId,
      })
    );
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [lisshowcount, setlisscount] = useState("");
  const [managerKey, setManagerKey] = useState(0);
  const [userselete, setuserselete] = useState(null);

  const loadOptionsPalyer = async (search, loadedOptions, page) => {
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/getuserforlinsense?search=${search}&page=${page}&user_id=${get_seller_profile?.referId}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?.id;
      data.label = data?.fullName;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  };
  const hendletoAssLicence = () => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? get_seller_profile?.Userchaindata?.license1
        : lisshowcount == 2
        ? get_seller_profile?.Userchaindata?.license2
        : lisshowcount == 3
        ? get_seller_profile?.Userchaindata?.license3
        : get_seller_profile?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else {
      dispatch(
        allapiAction.addlicence({
          userIdFrom: get_seller_profile?._id,
          userIdTo: userselete?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );
      setstatusLicence(false);
    }
  };

  const edituserKycByadmin = () => {
    // edituserKycByadmin
    // const data = new FormData();

    userData.page = page;

    dispatch(allapiAction.updateLevelAdmin(userData));

    setstatusEditKyc(false);
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Commssion",
      dataIndex: "commission",
      key: "commission",
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="delete">
              <span
                className="cursor-pointer ml-3"
                onClick={() => showModal(item._id)}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            <Tooltip title="Edit">
              <button
                className="admin-button ml-3 mt-3"
                onClick={() => hendleToViewEditKyc(item)}
              >
                Edit
              </button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);

    if (statusfinal) {
      navigate("/users?page=" + 1 + "&search=" + e + "&status=" + statusfinal);
    } else {
      navigate("/users?page=" + 1 + "&search=" + e);
    }
  };

  const handleChangeStatus = (e) => {
    // setsearch(e);
    navigate("/users?page=" + 1 + "&search=" + search + "&status=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (currentPage) => {
    setPage(currentPage);
    dispatch(allapiAction.getMarqueesList(currentPage));
  };

  return (
    <div>
      <Modal
        title="Delete Marquees"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add Amount"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Amount</Col>
            <Col span={24}>
              <Input
                name="amount"
                placeholder="enter amount"
                value={statusData?.amount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      {/* kyc status */}
      <Modal
        title="Marques"
        centered
        open={statusKycAp}
        onOk={() => hendleToKycSubmetUpdate(false)}
        onCancel={() => setstatusEditAp(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Level</Col>
            <Col span={24}>
              <Input
                name="Level"
                placeholder="enter Level"
                value={listdata?.length + 1}
                // onChange={(e) => {
                //   hendleTochangedata(e);
                // }}
              />
            </Col>

            <Col span={24}>Commission</Col>
            <Col span={24}>
              <Input
                name="commission"
                placeholder="enter commission"
                type="number"
                value={statusData?.commission}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        title="Edit "
        centered
        open={statusEditKyc}
        onOk={() => edituserKycByadmin(false)}
        onCancel={() => hendleToHideKyc(false)}
        width={500}
      >
        <div>
          <div className="d-flex mb-5">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="p-5">
                    <label>Level</label>
                    <input
                      name="level"
                      //   onChange={(e) => {
                      //     hendleToprofileEditKyc(e);
                      //   }}
                      value={userData?.level}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-5">
                    <label>Commission</label>
                    <input
                      name="commission"
                      onChange={(e) => {
                        hendleToprofileEditKyc(e);
                      }}
                      value={userData?.commission}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Level Dynamic"}
            subHeading={`Total ${users_list?.totalDocs || 0} Level Dynamic`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5"></Col>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5"></Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span
              className="jop_btn"
              onClick={() => {
                hendleToKycStatus();
              }}
            >
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 500,
            onChange(current) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.totalDocs ? users_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default LevelDynemic;
