import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import HeadingBack from "../component/HeadingBack";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Api from "../../Redux/common/api";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import ImageUploading from "react-images-uploading";
import { DeleteOutlined } from "@ant-design/icons";

function AddProduct() {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [aboutPage, setaboutPage] = useState("");
  const [images, setImages] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const [subList, setsubList] = useState([]);

  const get_sub_sub_category_list = useSelector((state) =>
    state.allapi.get_sub_sub_category_list
      ? state.allapi.get_sub_sub_category_list
      : []
  );

  console.log(rows);

  //   const maxNumber = 1

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImages(imageList);
  };

  //   console.log(rows);

  useEffect(() => {
    // dispatch(adminAction.getPermissionsList('dhsg'))
    // dispatch(adminAction.getProductList(1))
    dispatch(allapiAction.getsubsubcate({}));
    // dispatch(adminAction.getSellerListforpro({}))
    // dispatch(adminAction.searchtags({}))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  }, []);

  useEffect(() => {
    const dropList = get_sub_sub_category_list?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setsubList(dropList);
  }, [get_sub_sub_category_list]);

  const maxNumber = 8;

  //   const HendleImgFVer = (e, pictureDataURLs, index) => {
  //     console.log(e);
  //     console.log(pictureDataURLs);
  //     console.log(index);

  //     setRows((prevRows) => {
  //       const updatedRows = [...prevRows];
  //       console.log(updatedRows);
  //       updatedRows[index].image.push(e[e?.length - 1]);
  //       return updatedRows;
  //     });
  //   };

  console.log("page render");
  const HendleImgFVer = (e, pictureDataURLs, index) => {
    // Log to check if the function is getting the right parameters
    // console.log(e);
    // console.log(pictureDataURLs);
    // console.log(index);

    // Assuming that 'rows' is a state variable and 'setRows' is a state updater function
    setRows((prevRows) => {
      // Create a copy of the previous rows array to avoid mutating state directly
      const updatedRows = [...prevRows];
      console.log("111");
      // Assuming 'e' is an array of images, you want to push the last image to a specific row
      // You should check if 'updatedRows[index].image' is an array, and if not, initialize it as an array

      //   console.log(updatedRows[index].image);
      if (!Array.isArray(updatedRows[index].image)) {
        updatedRows[index].image = [];
      }

      // Push the last image from 'e' to the 'image' array in the specific row
      updatedRows[index].image.push(e[e.length - 1]);
      //   console.log(updatedRows);
      // Return the updated rows array to update the state
      console.log("222");

      return updatedRows;
    });
  };
  const onRemoveImage = (variantIndex, imageIndex) => {
    setRows((prevVariants) => {
      const updatedVariants = [...prevVariants];
      updatedVariants[variantIndex].image.splice(imageIndex, 1);
      return updatedVariants;
    });
  };

  const addSizeProduct = (index) => {
    console.log("test-0");
    setRows((prevRows) => {
      console.log("test-1");
      const updatedRows = [...prevRows];
      updatedRows[index].sizeProduct.push({ size: "", price: "", stock: "" });
      console.log("test-2");
      return updatedRows;
    });
  };

  const removeSizeProduct = (rowIndex, sizeIndex) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex].sizeProduct.splice(sizeIndex, 1);
      return updatedRows;
    });
  };
  const removeColorProduct = (index) => {
    // setRows((prevRows) => {
    //   const updatedRows = [...prevRows]
    //   updatedRows[rowIndex].sizeProduct.splice(sizeIndex, 1)
    //   return updatedRows
    // })

    setRows((prevRows) => prevRows.filter((row, i) => i !== index));
  };

  const handleSizeChange = (rowIndex, sizeIndex, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex].sizeProduct[sizeIndex][field] = value;
      return updatedRows;
    });
  };
  const handleColorChange = (rowIndex, sizeIndex, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex][field] = value;
      return updatedRows;
    });
  };
  const API_URl = URL.API_BASE_URL;
  const UPLOAD_ENDPOINT = "publicApi/productcon";

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("upload", file);
            fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                // console.log(res);
                resolve({ default: `${res.url}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  //   createProduct2

  const [productData, setproductData] = useState({});
  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setproductData({ ...productData, [name]: value });
  };

  const [btndis, setbtndis] = useState(false);
  const hendleTosubmit = async () => {
    const data = new FormData();

    if (images?.length == 0) {
      toast.error("please select feature Image");
    } else if (!productData?.title) {
      toast.error("please enter prodcat name");
    } else if (!selectedOption) {
      toast.error("please select category ");
    } /* else if (!selectedOption1) {
          toast.error('please select seller')
        } */ else {
      setbtndis(true);
      data.append("title", productData?.title);
      data.append("price", productData?.price);
      data.append("seo_title", productData?.seo_title);
      data.append("seo_description", productData?.seo_description);
      data.append("gst", productData?.gst);
      data.append("afterdiscountprice", productData?.afterdiscountprice);
      data.append("categoryId", selectedOption?._id);
      data.append("description", aboutPage);
      data.append("featureImage", images[0]?.file);

      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await Api.post(`${URL.createProduct2}`, data, config);

      // rows
      for (const row of rows) {
        console.log(row);
        const data2 = new FormData();
        data2.append("productId", response?.data?.data?._id);
        data2.append("color", row?.color);
        data2.append("gst", productData?.gst);
        data2.append("sizeProduct", JSON.stringify(row?.sizeProduct));

        row?.image?.forEach((file) => {
          // console.log(file?.file)
          data2.append("images", file?.file);
        });

        const response2 = await Api.post(
          `${URL.createProductvariations}`,
          data2,
          config
        );
      }
      setbtndis(false);
      navigate("/product");
    }
  };

  return (
    <div className=" code-box">
      <div className="flex">
        <div>
          <HeadingBack
            title={"Product"}
            // subHeading={`Total ${users_list?.totalDocs || 0} Users`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5"></Col>
      </div>

      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Feature</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <ImageUploading
                  // multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                  // acceptType={['jpg']}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        style={isDragging ? { color: "red" } : {}}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="btn btn-secondary"
                      >
                        Choose feature image
                      </button>
                      &nbsp;
                      <button
                        className="btn btn-secondary"
                        onClick={onImageRemoveAll}
                      >
                        Remove all images
                      </button>
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image.data_url} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Product Name</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a product name"
                  name="title"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">price</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a price"
                  name="price"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">after discount price</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a after discount price"
                  name="afterdiscountprice"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Gst</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a gst"
                  name="gst"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>

            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">seo title</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a seo title"
                  name="seo_title"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">seo description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a seo description"
                  name="seo_description"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Select Category</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>

                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={subList}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <CKEditor
                  editor={Editor}
                  data={aboutPage}
                  onReady={(editor) => {
                    // console.log('SDfsf')
                    // console.log(editor)
                  }}
                  onChange={(event, editor) => {
                    // console.log(editor)
                    const data = editor.getData();
                    setaboutPage(data);
                  }}
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  onBlur={(event, editor) => {
                    console.log("SDfsf");
                  }}
                  onFocus={(event, editor) => {
                    console.log("SDfsf");
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <h5>Variations</h5>
        {rows.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            {row.sizeProduct.map((sizeProduct, sizeIndex) => (
              <div>
                {sizeIndex === 0 && (
                  <div className="row">
                    <div className="col-4">
                      <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mb-2">
                          <span className="required">Color</span>
                          <i
                            className="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="popover"
                            data-bs-trigger="hover"
                            data-bs-html="true"
                            data-bs-content="Permission names is required to be unique."
                          ></i>
                        </label>
                        <input
                          // className='form-control form-control-solid'
                          placeholder="Enter color"
                          name="seo_description"
                          value={row.color}
                          onChange={(e) =>
                            handleColorChange(
                              rowIndex,
                              sizeIndex,
                              "color",
                              e.target.value
                            )
                          }
                          className="form-control bg-transparent"
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <ImageUploading
                        //   withIcon={true}
                        multiple
                        value={row?.image}
                        // onChange={onChange}
                        onChange={(e, defaultImages) => {
                          HendleImgFVer(e, defaultImages, rowIndex);
                        }}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                        acceptType={["png", "jpeg", "jpg", "webp"]}
                        // acceptType={['jpg']}
                      >
                        {({ imageList, onImageUpload, onImageRemove }) => (
                          <div>
                            {imageList.map((image, imageIndex) => (
                              <div
                                key={imageIndex}
                                className={"proadDImgContainer"}
                              >
                                <div className="image-item">
                                  <img
                                    width="100"
                                    src={image.data_url}
                                    alt={`Variant ${rowIndex + 1}, Image ${
                                      imageIndex + 1
                                    }`}
                                  />
                                </div>

                                <button
                                  className="proadDImgBtn btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary"
                                  onClick={() =>
                                    onRemoveImage(rowIndex, imageIndex)
                                  }
                                >
                                  <i className="fas fa-trash-alt"></i>
                                  <DeleteOutlined />
                                  {/* Remove */}
                                </button>
                              </div>
                            ))}
                            <button
                              className="btn btn-success mt-5"
                              onClick={onImageUpload}
                            >
                              Add Image
                            </button>
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                  </div>
                )}
                {/* <h6>Size</h6> */}
                <div className="row">
                  <div className="col-3">
                    {" "}
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mb-2">
                        <span className="required">Size</span>
                        <i
                          className="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-html="true"
                          data-bs-content="Permission names is required to be unique."
                        ></i>
                      </label>
                      <input
                        // className='form-control form-control-solid'
                        placeholder="Enter Size"
                        name="size"
                        value={sizeProduct.size}
                        onChange={(e) =>
                          handleSizeChange(
                            rowIndex,
                            sizeIndex,
                            "size",
                            e.target.value
                          )
                        }
                        className="form-control bg-transparent"
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    {" "}
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mb-2">
                        <span className="required">price</span>
                        <i
                          className="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-html="true"
                          data-bs-content="Permission names is required to be unique."
                        ></i>
                      </label>
                      <input
                        // className='form-control form-control-solid'
                        type="number"
                        placeholder="Enter price"
                        name="price"
                        value={sizeProduct.price}
                        onChange={(e) =>
                          handleSizeChange(
                            rowIndex,
                            sizeIndex,
                            "price",
                            e.target.value
                          )
                        }
                        className="form-control bg-transparent"
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    {" "}
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mb-2">
                        <span className="required">after discount price</span>
                        <i
                          className="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-html="true"
                          data-bs-content="Permission names is required to be unique."
                        ></i>
                      </label>
                      <input
                        // className='form-control form-control-solid'
                        placeholder="Enter after discount price"
                        type="number"
                        name="afterdiscountprice"
                        value={sizeProduct.afterdiscountprice}
                        onChange={(e) =>
                          handleSizeChange(
                            rowIndex,
                            sizeIndex,
                            "afterdiscountprice",
                            e.target.value
                          )
                        }
                        className="form-control bg-transparent"
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mb-2">
                        <span className="required">stock</span>
                        <i
                          className="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-html="true"
                          data-bs-content="Permission names is required to be unique."
                        ></i>
                      </label>
                      <input
                        // className='form-control form-control-solid'
                        placeholder="Enter stock"
                        name="stock"
                        type="number"
                        value={sizeProduct.stock}
                        onChange={(e) =>
                          handleSizeChange(
                            rowIndex,
                            sizeIndex,
                            "stock",
                            e.target.value
                          )
                        }
                        className="form-control bg-transparent"
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    {row.sizeProduct.length > 1 && (
                      <button
                        className="btn btn-danger"
                        onClick={() => removeSizeProduct(rowIndex, sizeIndex)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}

            <div className="col-4">
              {" "}
              <button
                className="btn btn-success"
                onClick={() => addSizeProduct(rowIndex)}
              >
                Add Size
              </button>
              <button
                className="btn btn-danger ms-5"
                onClick={() => removeColorProduct(rowIndex)}
              >
                Remove Color
              </button>
            </div>

            <hr />
          </React.Fragment>
        ))}
        <table></table>

        <div className="row">
          <div className="col-8">
            {" "}
            <button
              className="btn btn-success"
              onClick={() =>
                setRows((prevRows) => [
                  ...prevRows,
                  {
                    color: "",
                    image: [],
                    sizeProduct: [{ size: "", price: "", stock: "" }],
                  },
                ])
              }
            >
              Add Row
            </button>
          </div>

          <div className="col-4">
            {" "}
            <button
            disabled={btndis}
              className={btndis ?"btn btn-primary" : "btn btn-primary"}
              onClick={() => hendleTosubmit()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
