import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import HeadingBack from "../component/HeadingBack";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { toast } from "react-toastify";
import Api from "../../Redux/common/api";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import ImageUploading from "react-images-uploading";

function EducationEdit() {
  const dispatch = useDispatch();
  const [aboutPage, setaboutPage] = useState("");
  const [hidescription, setHindiDesc] = useState("");
  const [images, setImages] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const [subList, setsubList] = useState([]);
  const [productData, setproductData] = useState({});
  const { id } = useParams();
  const get_sub_sub_category_list = useSelector((state) =>
    state.allapi.edu_cate_all ? state.allapi.edu_cate_all : []
  );
  const get_product_details = useSelector((state) =>
    state.allapi.get_ledu_details_list ? state.allapi.get_ledu_details_list : {}
  );

  useEffect(() => {
    const selectCate = subList.find(
      (edata) => edata?._id === get_product_details?.categoryId
    );
    if (get_product_details?.description !== undefined) {
      setaboutPage(get_product_details?.description);
    }
    if (get_product_details?.hidescription !== undefined) {
      setHindiDesc(get_product_details?.hidescription);
    }

    setSelectedOption(selectCate);
    setproductData(get_product_details);

    setImages([{ data_url: URL.API_BASE_URL + get_product_details.image }]);
  }, [get_product_details]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  useEffect(() => {
    dispatch(allapiAction.geteducatetttcate({}));
    dispatch(allapiAction.geteducatetDetails(id));
    return () => {};
  }, []);

  useEffect(() => {
    const dropList = get_sub_sub_category_list?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setsubList(dropList);
  }, [get_sub_sub_category_list]);

  const maxNumber = 8;
  const API_URl = URL.API_BASE_URL;
  const UPLOAD_ENDPOINT = "publicApi/productcon";

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("upload", file);
            fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${res.url}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const UPLOAD_ENDPOINT2 = "publicApi/productcondocs";
  const [newdoc, setnewdoc] = useState("");

  function copy() {
    const el = document.createElement("input");
    el.value = newdoc;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast.success("Copied!");
  }

  const hendletoUploaddoc = async (e) => {
    const body = new FormData();
    body.append("upload", e?.target?.files[0]);
    fetch(`${API_URl}/${UPLOAD_ENDPOINT2}`, {
      method: "post",
      body: body,
    })
      .then((res) => res.json())
      .then((res) => {
        setnewdoc(res.url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setproductData({ ...productData, [name]: value });
  };

  const hendleTosubmit = async () => {
    const data = new FormData();
    if (images?.length === 0) {
      toast.error("please select feature Image");
    } else if (!productData?.name) {
      toast.error("please enter name");
    } else if (!selectedOption) {
      toast.error("please select category ");
    } else {
      data.append("_id", productData?._id);
      data.append("name", productData?.name);
      data.append("keyword", productData?.keyword);
      data.append("meta_title", productData?.meta_title);
      data.append("meta_description", productData?.meta_description);
      data.append("categoryId", selectedOption?._id);
      data.append("description", aboutPage);
      data.append("hidescription", hidescription);
      if (images[0]?.file) {
        data.append("image", images[0]?.file);
      }

      const token = localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      await Api.post(`${URL.educationallUpdateadmin}`, data, config);
      navigate("/education-all");
    }
  };

  return (
    <div className="code-box">
      <style jsx>{`
        .editor-container {
          max-height: 400px; /* Limit the maximum height */
          overflow: hidden;
        }
        .ck-editor__editable {
          max-height: 400px; /* Limit the maximum height of the editable area */
          overflow-y: auto; /* Enable vertical scrolling */
        }
      `}</style>
      <div className="flex">
        <div>
          <HeadingBack title={"Product"} />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5"></Col>
      </div>

      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Banner</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <ImageUploading
                  // multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                  // acceptType={['jpg']}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        style={isDragging ? { color: "red" } : {}}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="btn btn-secondary"
                      >
                        Choose feature image
                      </button>
                      &nbsp;
                      <button
                        className="btn btn-secondary"
                        onClick={onImageRemoveAll}
                      >
                        Remove all images
                      </button>
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image.data_url} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Name</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a  name"
                  name="name"
                  value={productData?.name}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Keyword</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a keyword"
                  name="keyword"
                  value={productData?.keyword}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>

            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">meta title</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a meta title"
                  name="meta_title"
                  value={productData?.meta_title}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">seo description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a meta description"
                  name="meta_description"
                  value={productData?.meta_description}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Select Category</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>

                <Select
                  value={selectedOption}
                  onChange={setSelectedOption}
                  options={subList}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Upload doc and get url</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  type={"file"}
                  // className='form-control form-control-solid'
                  placeholder="Enter a seo description"
                  name="seo_description"
                  onChange={(e) => {
                    hendletoUploaddoc(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Docs</span>
                  {/* <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i> */}
                </label>
                {newdoc}

                {newdoc && (
                  <button
                    onClick={() => {
                      copy();
                    }}
                    className=" btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary"
                  >
                    copy
                  </button>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <CKEditor
                  editor={Editor}
                  data={aboutPage}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setaboutPage(data);
                  }}
                  config={{
                    extraPlugins: [uploadPlugin],
                    height: 400,
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Description In Hindi</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <CKEditor
                  editor={Editor}
                  data={hidescription ? hidescription : ""}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setHindiDesc(data);
                  }}
                  config={{
                    extraPlugins: [uploadPlugin],
                    height: 400,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            {" "}
            <button
              className="btn btn-bg-primary"
              onClick={() => hendleTosubmit()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EducationEdit;
