import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeadingBack from "../component/HeadingBack";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { toast } from "react-toastify";
import Api from "../../Redux/common/api";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import ImageUploading from "react-images-uploading";
import { DeleteOutlined } from "@ant-design/icons";

function EducationAdd() {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [aboutPage, setaboutPage] = useState("");
  const [hindiDesc, setHindiDesc] = useState("");
  const [images, setImages] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const [subList, setsubList] = useState([]);

  const get_sub_sub_category_list = useSelector((state) =>
    state.allapi.edu_cate_all ? state.allapi.edu_cate_all : []
  );

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  useEffect(() => {
    dispatch(allapiAction.geteducatetttcate({}));
    return () => {};
  }, []);

  useEffect(() => {
    const dropList = get_sub_sub_category_list?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;
      return data;
    });

    setsubList(dropList);
  }, [get_sub_sub_category_list]);

  const maxNumber = 8;
  const HendleImgFVer = (e, pictureDataURLs, index) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      if (!Array.isArray(updatedRows[index].image)) {
        updatedRows[index].image = [];
      }
      updatedRows[index].image.push(e[e.length - 1]);
      return updatedRows;
    });
  };
  const onRemoveImage = (variantIndex, imageIndex) => {
    setRows((prevVariants) => {
      const updatedVariants = [...prevVariants];
      updatedVariants[variantIndex].image.splice(imageIndex, 1);
      return updatedVariants;
    });
  };

  const addSizeProduct = (index) => {
    console.log("test-0");
    setRows((prevRows) => {
      console.log("test-1");
      const updatedRows = [...prevRows];
      updatedRows[index].sizeProduct.push({ size: "", price: "", stock: "" });
      console.log("test-2");
      return updatedRows;
    });
  };

  const removeSizeProduct = (rowIndex, sizeIndex) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex].sizeProduct.splice(sizeIndex, 1);
      return updatedRows;
    });
  };
  const removeColorProduct = (index) => {
    setRows((prevRows) => prevRows.filter((row, i) => i !== index));
  };

  const handleSizeChange = (rowIndex, sizeIndex, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex].sizeProduct[sizeIndex][field] = value;
      return updatedRows;
    });
  };
  const handleColorChange = (rowIndex, sizeIndex, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex][field] = value;
      return updatedRows;
    });
  };
  const API_URl = URL.API_BASE_URL;
  const UPLOAD_ENDPOINT = "publicApi/productcon";
  const UPLOAD_ENDPOINT2 = "publicApi/productcondocs";

  const [newdoc, setnewdoc] = useState("");

  function copy() {
    const el = document.createElement("input");
    el.value = newdoc;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast.success("Copied!");
  }

  const hendletoUploaddoc = async (e) => {
    console.log(e?.target?.files[0]);
    const body = new FormData();
    body.append("upload", e?.target?.files[0]);
    fetch(`${API_URl}/${UPLOAD_ENDPOINT2}`, {
      method: "post",
      body: body,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setnewdoc(res.url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("upload", file);
            fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${res.url}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const [productData, setproductData] = useState({});
  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setproductData({ ...productData, [name]: value });
  };

  const [btndis, setbtndis] = useState(false);
  const hendleTosubmit = async () => {
    const data = new FormData();

    if (images?.length == 0) {
      toast.error("please select feature Image");
    } else if (!productData?.title) {
      toast.error("please enter prodcat name");
    } else if (!selectedOption) {
      toast.error("please select category ");
    } else {
      setbtndis(true);
      data.append("name", productData?.title);
      data.append("keyword", productData?.keyword);
      data.append("meta_title", productData?.seo_title);
      data.append("meta_description", productData?.seo_description);
      data.append("categoryId", selectedOption?._id);
      data.append("description", aboutPage);
      data.append("hidescription", hindiDesc);
      data.append("image", images[0]?.file);

      const token = localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      await Api.post(`${URL.educationAllCreate}`, data, config);
      setbtndis(false);
      navigate("/education-all");
    }
  };

  return (
    <div className=" code-box">
      <div className="flex">
        <div>
          <HeadingBack title={"Add Education"} />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5"></Col>
      </div>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Banner</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <ImageUploading
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    <div className="upload__image-wrapper">
                      <button
                        style={isDragging ? { color: "red" } : {}}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="btn btn-secondary"
                      >
                        Choose Banner image
                      </button>
                      &nbsp;
                      <button
                        className="btn btn-secondary"
                        onClick={onImageRemoveAll}
                      >
                        Remove images
                      </button>
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image.data_url} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required"> Name</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  placeholder="Enter a name"
                  name="title"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required"> Key word</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  placeholder="Enter a keyword"
                  name="keyword"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">seo title</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  placeholder="Enter a seo title"
                  name="seo_title"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">seo description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  placeholder="Enter a seo description"
                  name="seo_description"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Select Category</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>

                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={subList}
                />
              </div>
            </div>

            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Upload doc and get url</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  type={"file"}
                  placeholder="Enter a seo description"
                  name="seo_description"
                  onChange={(e) => {
                    hendletoUploaddoc(e);
                  }}
                  className="form-control bg-transparent"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Docs</span>
                </label>
                {newdoc}
                {newdoc && (
                  <button
                    onClick={() => {
                      copy();
                    }}
                    className=" btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary"
                  >
                    copy
                  </button>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <CKEditor
                  editor={Editor}
                  data={aboutPage}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setaboutPage(data);
                  }}
                  config={{
                    extraPlugins: [uploadPlugin],
                    height: 400,
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Description In Hindi</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <CKEditor
                  editor={Editor}
                  data={hindiDesc}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setHindiDesc(data);
                  }}
                  config={{
                    extraPlugins: [uploadPlugin],
                    height: 400,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {rows.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            {row.sizeProduct.map((sizeProduct, sizeIndex) => (
              <div>
                {sizeIndex === 0 && (
                  <div className="row">
                    <div className="col-4">
                      <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mb-2">
                          <span className="required">Color</span>
                          <i
                            className="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="popover"
                            data-bs-trigger="hover"
                            data-bs-html="true"
                            data-bs-content="Permission names is required to be unique."
                          ></i>
                        </label>
                        <input
                          placeholder="Enter color"
                          name="seo_description"
                          value={row.color}
                          onChange={(e) =>
                            handleColorChange(
                              rowIndex,
                              sizeIndex,
                              "color",
                              e.target.value
                            )
                          }
                          className="form-control bg-transparent"
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <ImageUploading
                        multiple
                        value={row?.image}
                        onChange={(e, defaultImages) => {
                          HendleImgFVer(e, defaultImages, rowIndex);
                        }}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                        acceptType={["png", "jpeg", "jpg", "webp"]}
                      >
                        {({ imageList, onImageUpload, onImageRemove }) => (
                          <div>
                            {imageList.map((image, imageIndex) => (
                              <div
                                key={imageIndex}
                                className={"proadDImgContainer"}
                              >
                                <div className="image-item">
                                  <img
                                    width="100"
                                    src={image.data_url}
                                    alt={`Variant ${rowIndex + 1}, Image ${
                                      imageIndex + 1
                                    }`}
                                  />
                                </div>

                                <button
                                  className="proadDImgBtn btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary"
                                  onClick={() =>
                                    onRemoveImage(rowIndex, imageIndex)
                                  }
                                >
                                  <i className="fas fa-trash-alt"></i>
                                  <DeleteOutlined />
                                </button>
                              </div>
                            ))}
                            <button
                              className="btn btn-success mt-5"
                              onClick={onImageUpload}
                            >
                              Add Image
                            </button>
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-3">
                    {" "}
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mb-2">
                        <span className="required">Size</span>
                        <i
                          className="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-html="true"
                          data-bs-content="Permission names is required to be unique."
                        ></i>
                      </label>
                      <input
                        placeholder="Enter Size"
                        name="size"
                        value={sizeProduct.size}
                        onChange={(e) =>
                          handleSizeChange(
                            rowIndex,
                            sizeIndex,
                            "size",
                            e.target.value
                          )
                        }
                        className="form-control bg-transparent"
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    {" "}
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mb-2">
                        <span className="required">price</span>
                        <i
                          className="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-html="true"
                          data-bs-content="Permission names is required to be unique."
                        ></i>
                      </label>
                      <input
                        type="number"
                        placeholder="Enter price"
                        name="price"
                        value={sizeProduct.price}
                        onChange={(e) =>
                          handleSizeChange(
                            rowIndex,
                            sizeIndex,
                            "price",
                            e.target.value
                          )
                        }
                        className="form-control bg-transparent"
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    {" "}
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mb-2">
                        <span className="required">after discount price</span>
                        <i
                          className="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-html="true"
                          data-bs-content="Permission names is required to be unique."
                        ></i>
                      </label>
                      <input
                        placeholder="Enter after discount price"
                        type="number"
                        name="afterdiscountprice"
                        value={sizeProduct.afterdiscountprice}
                        onChange={(e) =>
                          handleSizeChange(
                            rowIndex,
                            sizeIndex,
                            "afterdiscountprice",
                            e.target.value
                          )
                        }
                        className="form-control bg-transparent"
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-semibold form-label mb-2">
                        <span className="required">stock</span>
                        <i
                          className="fas fa-exclamation-circle ms-2 fs-7"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-html="true"
                          data-bs-content="Permission names is required to be unique."
                        ></i>
                      </label>
                      <input
                        placeholder="Enter stock"
                        name="stock"
                        type="number"
                        value={sizeProduct.stock}
                        onChange={(e) =>
                          handleSizeChange(
                            rowIndex,
                            sizeIndex,
                            "stock",
                            e.target.value
                          )
                        }
                        className="form-control bg-transparent"
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    {row.sizeProduct.length > 1 && (
                      <button
                        className="btn btn-danger"
                        onClick={() => removeSizeProduct(rowIndex, sizeIndex)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}

            <div className="col-4">
              {" "}
              <button
                className="btn btn-success"
                onClick={() => addSizeProduct(rowIndex)}
              >
                Add Size
              </button>
              <button
                className="btn btn-danger ms-5"
                onClick={() => removeColorProduct(rowIndex)}
              >
                Remove Color
              </button>
            </div>

            <hr />
          </React.Fragment>
        ))}
        <table></table>
        <div className="row">
          <div className="col-8"></div>
          <div className="col-4">
            {" "}
            <button
              disabled={btndis}
              className={btndis ? "btn btn-primary" : "btn btn-primary"}
              onClick={() => hendleTosubmit()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EducationAdd;
